.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2em 0;
    font-size: calc(10px + 2vmin);
}

.trivia-blFont{
    margin: 0;
    padding: 0;
    font-size: 1.12em;
    line-height: 1em;
    font-weight: 700;
    color: black;
    text-align: center;
}
.trivia-bmFont{
    font-size: 0.7em;
    line-height: 1em;
    font-weight: 600;
    color: black;
    text-align: center;
}
.trivia-bsFont{
    font-size: 0.4em;
    line-height: 1em;
    font-weight: 600;
    color: black;
    text-align: center;
}
.trivia-bcmFont{
    font-size: 0.7em;
    font-weight: 900;
    color: black;
}

.trivia-gmFont{
    margin: 0;
    padding: 0;
    font-size: 1em;
    color: rgb(90, 90, 90);
}
.trivia-gsFont{
    margin: 0;
    padding: 0;
    font-size: 0.6em;
    color: rgb(90, 90, 90);
}
.trivia-gxsFont{
    margin: 0;
    padding: 0;
    font-size: 0.4em;
    color: rgb(90, 90, 90);
}
.trivia-glFont{
    margin: 0;
    padding: 0;
    font-size: 1.12em;
    line-height: 1em;
    font-weight: 700;
    color: rgba(23, 161, 80, 1);
}
.trivia-rlFont{
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: 700;
    color: red;
}
.trivia-rmFont{
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: 500;
    color: red;
}
.trivia-rsFont{
    margin: 0;
    padding: 0;
    font-size: 0.6em;
    color: rgba(255, 0, 0, 0.5);
}
.trivia-wmFont{
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: 600;
    color: white;
}
.trivia-wcmFont{
    margin: 0;
    padding: 0;
    font-size: 1em;
    font-weight: 500;
    color: white;
}

.footer-link{
    text-decoration: none;
    display: block;
    margin-bottom: 7px;
}
.footer-wsFont{
    font-size: 1em;
    font-weight: 300;
    color: white;
}
.footer-wlFont{
    font-size: 1.2em;
    font-weight: 400;
    color: white;
}
.trivia-bbutton{
    background-color: black;
    color: white;
    border-radius: 1px;
    width: 200px;
}
.trivia-gbutton{
    background-color: rgba(23, 161, 80, 1);
    color: white;
    border-radius: 1px;
    width: 200px;
}
.trivia-steps{
    margin: 0;
    padding: 0;
    font-size: 0.5em;
    color: rgb(90, 90, 90);
    background-color:rgba(23, 161, 80, 1) !important;
    border-radius: 2px !important;
}
.trivia-bases{
    margin: 0;
    padding: 0;
    font-size: 0.6em;
    color: rgb(90, 90, 90);
    background-color:rgba(23, 161, 80, 1) !important;
    border-radius: 2px !important;
}
.trivia-basesContainer{
    min-height:60px !important;
}
.trivia-stepsContainer{
    min-height:40px !important;
}
.MuiTimelineItem-missingOppositeContent:before{
    flex: 0 !important;
    padding: 0 !important;
}
.CustomInputStyles{
    background-color: blue;
  width:10000px;
  cursor: pointer;
  
}
.CustomInputStyle{
  width:100%;
  z-index: 11111 !important;
  cursor: pointer;
  
}
.react-datepicker-wrapper {
    display: inline !important;
}

.invisible {
    display: none;
}

.button-form-login-style {
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    margin: 1em;
    width: 20em;
    background-color: rgb(23, 161, 80);
    color: white;
    cursor: default;
    box-shadow: none;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 0;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: #A81127 !important;
}
.MuiFormLabel-root.Mui-focused {
    color: #A81127 !important;
}
.MuiCheckbox-colorPrimary.Mui-checked{
    color: #A81127 !important;
}